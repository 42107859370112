.forgot_bars_active {
    height: 8px;
    width: 100%;
    background: #ff364c;
    margin: 0 2px;
  }
  .forgot_bars_container {
    height: 10px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    /* border: 1px solid red; */
  }
  .forgot_bars {
    height: 8px;
    width: 100%;
    background: #4b1018;
    margin: 0 2px;
  }