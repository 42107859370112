footer {
  background: #000;
  padding-top: 270px;
  position: relative;
}

.topLevelFooter {
  padding: 60px 90px;
  justify-content: space-between;
  color: #fff;
  background: #444444;
  border-radius: 10px;
  position: absolute;
  top: -310px;
  width: 100%;
  left: 0;
  right: 0;
  max-width: 1280px;
  margin: 0 auto;
}

.topLevelLeft {
  max-width: 580px;
  width: 100%;
}

.topLevelRight {
  max-width: 480px;
  width: 100%;
}

.footerHeading {
  font-weight: 700;
  font-size: 36px;
  line-height: 130%;
}

.footerText {
  font-weight: 400;
  font-size: 16px;
  line-height: 155%;
}

.flexBox.bottomLevelFooter {
  align-items: center;
  padding: 24px 0;
  border-bottom: 1px solid #565656;
}

.footerList {
  gap: 52px;
}

.footerList a {
  color: #fff;
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.flexBox.footerSocial {
  padding: 24px 0 40px;
  align-items: center;
}

.footerText {
  color: #fff;
}

.footerText2 {
  font-size: 14px;
}

.socialIcons svg:not(:first-child) {
  margin-left: 24px;
}

.whiteForm {
  padding: 32px;
  background: #fff;
  border-radius: 8px;
}

.topLevelRight button {
  width: 100%;
}

.listBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.listBox span {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}
.msgSent {
  text-align: center;
  color: #000;
  margin-top: 10px;
}
footer{
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}
.footer_ul_amrc{
  list-style-type: none;
  padding: initial;
}
.footer_ul_amrc .p{
  cursor: default;
}
.bg_black {
  background: #000 !important;
}

.bottomLevelFooter .row{
  cursor: default !important;
}

@media only screen and (max-width: 1080px) {
   .topLevelFooter{
    position: relative;
    padding: 20px;
     top: 0;
   }
   .topLevelRight{
    margin: 20px 0px;
   }
   footer{
    padding-top: 20px;
   }
 }