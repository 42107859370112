.adminSideMenuWrap{
    max-width: 270px;
    width: 100%;
    height: 100vh;
    padding-top: 100px;
    background: #323232;
}

.sideMenu li{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    background: transparent;
    transition: 0.4s ease-in-out;
    cursor: pointer;
}

.sideMenu li a{
    padding: 16px 24px;
    display: block;
    color: inherit;
    text-decoration: none;
}

.sideMenu li.active,
.sideMenu li:hover{
    background: #FF364C;
    transition: 0.4s ease-in-out;
}