.ant-table-container{
    border-inline-start :none !important
}

.ant-table{
    background-color: transparent !important;
    overflow: auto !important;
}

.ant-table .ant-empty{
    height: 480px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
}
table{
border: 0px !important;

}
.ant-table-thead .ant-table-cell{
    background: #444444 !important;
    border-bottom: 0px !important;
   }

.ant-table-cell{
    color:  #fff !important;
    border-radius : 0px !important;
    border-inline-end: none !important;
   }
.ant-table-cell-row-hover{
    color:  #090909 !important;
    background-color: #fff !important;
    border-radius : 0px !important;
    border-inline-end: none !important;
   }
.ant-table-tbody td{
padding-top: 15px !important;
padding-bottom: 15px !important;
}
/* wfm css */
 
 
  .data-table .ant-pagination {
    display:none;
  }
  /* .custom-pagination {
  
    .ant-pagination {
     
     
      .ant-pagination-prev, .ant-pagination-item, .ant-pagination-jump-next, .ant-pagination-next {
        margin-top: 5px;
        height: unset;
        margin-bottom: 5px;
        margin-right: 0;
        border: none;
        font-size: 15px;
        &.ant-pagination-item-active, &:hover {
          background: #2dcc7061;
          border-radius: 4px;
          color: #4f4f4f;
          a {
            color: black;
          }
        }
      }
    }
  
   
  } */
  .custom-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    color: #828282;
  }
  .custom-pagination > .ant-pagination-prev, .ant-pagination-item, .ant-pagination-jump-next, .ant-pagination-next {
    margin-top: 5px;
    height: unset;
    margin-bottom: 5px;
    margin-right: 0;
    border: none;
    font-size: 15px;
  }
  .custom-pagination .ant-pagination {
    display: block !important;
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 7px;
  }
  .custom-pagination .ant-pagination-options {
    display: none !important;
  }

  .custom-pagination

  .action-menu{
    margin-top: 5px;
    border-radius: 50%;
    height: 36px;
    width: 36px;
    border: 1px solid rgb(217, 217, 217);
    padding: 4px 9px !important;
  }

   @media only screen and (max-width: 425px) {
    .custom-pagination {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 10px;
    }  
    }
  