.popupWrap{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s ease-in-out;

    display: flex;
    align-items: center;
    justify-content: center;
}

.popupWrap.active{
    z-index: 3500;
    opacity: 1;
    visibility: visible;
    transition: 0.4s ease-in-out;
}

.popupContainer{
    width: 870px;
    background: #323232;
    border-radius: 10px;
    padding: 32px;
    margin: auto;
    z-index: 1000;
}

.popupHeading{
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    color: #fff;
    text-align: center;
}

.btnWrapBox{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.btnWrapBox button{
    margin: 0;
}