.governmentWrap{
    height: calc(100vh - 80px);
    width: 100%;
    background: #000;
    padding: 0;
    margin-top: 80px;
    padding: 20px;
    overflow: auto;
}

.governmentWrap .boxBusiness{
    position: relative;
}

.governmentWrap .boxBusiness .text-3{
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    min-height: 44px;
}

.floatingBtns{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 10px;
    gap: 5px;
}

.floatingBtns img{
    max-width: 30px;
    cursor: pointer;
}