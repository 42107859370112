@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
}

img {
  max-width: 100%;
  line-height: 0;
}

.container {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

/* margin top */
.acs-bg-color{
  background-color: #FF364C;;
}
.acs-text-color{
  color: #FF364C;
}
.acs-text-light-color{
  color: #fb4a5e;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

main {
  position: relative;
  padding-bottom: 320px;
  background: #313131 ;
}
.main-footer {
  position: relative;
  padding-bottom: 0px;
 
}
.container.contact .topLevelFooter {
  top: -370px;
}

.container.contact .bottomLevelFooter {
  margin-top: 100px;
}

main .clearAll {
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

@media only screen and (max-width: 600px) {
  main {
    padding-bottom: 70px !important;
  }
}
