.sliderBox {
  position: relative;
}

.swiperMain {
  max-height: 720px;
  overflow: hidden;
}
/* 
.siderText {
  background-color: aqua;
  position: absolute;
  bottom: 245px;
  left: 160px;
  max-width: 600px;
  color: #fff;
} */

.siderText {
  /* background-color: aqua; */
  position: absolute;
  bottom: 0;
  left:160px;
  height: 100%;
  max-width: 600px;
  color: #fff;
}

.heroText {
  font-weight: 800;
  font-size: 40px;
  line-height: 120%;
  margin-bottom: 12px;
}

.heroDetail {
  font-weight: 400;
  font-size: 16px;
  line-height: 170%;
}

.sliderBox img {
  width: 100%;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 0;
  left: 0;
  width: 74px;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 99;
  cursor: pointer;
}

.swiper-button-next {
  left: auto;
  right: 0;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: "";
  background: url("./sliderPrev.png");
  background-size: cover !important;
  display: block;
  position: relative;
  width: 100%;
  height: 74px;
}

.swiper-button-next::after {
  background: url("./sliderNext.png");
}

.swiper-button-disabled {
  cursor: not-allowed;
}

section {
  padding: 100px 0;
  background: #313131;

}

.gradientBG {
  /* background: linear-gradient(180deg, #000000 0%, #757575 100%); */
  background: #313131;
}

.topDetails {
  text-align: center;
  color: #fff;
}

.commonHeading {
  font-weight: 700;
  font-size: 36px;
  line-height: 130%;
}

.commonText {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #d6d6d6;
}

.x3BoxWrap {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 30px;
}

.breakerHeading {
  font-weight: 600;
  font-size: 32px;
  line-height: 28px;
  color: #fff;
}

.breakerBlack {
  background: #000 url("./ContactusBG.jpg");
  background-size: cover;
}

.breakerBlack .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heading-2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
}

.text-2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 200%;
}

.flexBox {

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center;
}

.aboutLeft {
  max-width: 490px;
  width: 100%;
  line-height: 0;
  border-radius: 8px;
}

/* .aboutRight {
  max-width: calc(100% - 550px);
} */

.aboutWrapper {
  color: #fff;
}

.aboutWrapper .flexBox {
  gap: 60px;
}

.shadowLeft {
  box-shadow: -8px 8px rgb(255 54 76 / 50%);
}

.shadowRight {
  box-shadow: 7px 8px rgb(255 54 76 / 50%);
}

.testimonila {
  background: #000 url("./TestimonialBG.png");
  background-size: cover;
}

.testimonialsBox {
  height: 300px;
  max-height: 400px;
  color: #fff;
  padding: 40px;
  background: #444444;
  box-shadow: 0px 12.3429px 32.9143px rgba(0, 0, 0, 0.12), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12.3429px;
}

.swiperBox {
  /* max-width: 1140px; */
  margin: 0 auto;
}

.swiperBox .swiper {
  padding: 0 100px !important;
}

.testminoal {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
}

.testimonialBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
}

.testiName {
  font-weight: 500;
  font-size: 18px;
  line-height: 33px;
  color: #ff364c;
}

.textTesti {
  font-weight: 400;
  font-size: 14px;
}

.commonTable {
  width: 100%;
  color: #fff;
  border-collapse: collapse;
}

.commonTable thead {
  background: #444444;
  text-align: left;
}

.commonTable thead th {
  padding: 10px 16px;
  font-weight: 400;
  width: 20%;
  color: #e7e7e7;
  font-size: 14px;
}

.commonTable thead th:first-child,
.commonTable tbody td:first-child {
  width: 40%;
}

.commonTable tbody td:first-child {
  font-weight: 500;
}

.commonTable tbody td {
  padding: 25px 16px;
  font-size: 18px;
  line-height: 30px;
  border-bottom: 1px solid #999;
  width: 20%;
  font-size: 16px;
}

.redlink {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  text-decoration: none;
  color: #ff364c;
}

.items-center {
  align-items: center;
}
 
@media only screen and (max-width: 768px) {
  .breakerHeading{
    font-size: 20px !important;
  }
  .flexBox {
    /* display: grid;
    grid-template-columns: auto;
    */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .testimonialBox {
     display: flex;
     justify-content: center;
     align-items: center;
  }
  .swiperBox .swiper {
    padding: 0 20px !important;
  }
  .main .aboutRight {
    margin-top: 50px;
    /* max-width: 490px; */
  }
 .sliderBox{
  height: 400px !important;

 }
 .heroDetail{
  line-height: 130%;
 }
 .sliderBox img{
  height: 400px;
  object-fit: cover;
 }
  .siderText {
    position: absolute;
    left: 10px;
    max-height: 400px;
    max-width: 600px;
    color: #fff;
    line-height: 10%;
  }
}

.jobOpening{
  padding: 0px 0px !important;
}


@media screen and (max-width: 1080px) {
  .breakerBlack .container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

  }
  .breakerHeading{
   margin: 20px;
  }

}
 