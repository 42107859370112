.containerDir {
  padding: 0;
  padding-bottom: 60px;
}

.containerDir .container {
  background-color: white;
  background-size: cover !important;
  border-radius: 10px;
  padding: 4rem 6rem;
}

.logo-wrapper {
  position: relative;
  max-width: 80px;
  padding-bottom: 20px;
}

.logo-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.containerDir a {
  color: black;
}
.containerDir a:hover {
  color: #ff364c;
}
@media only screen and (max-device-width: 768px) {
  .containerDir .container {
    padding: 2rem 3rem;
  }
}
