header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
}

.logoAlign {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.navigationWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0;
  list-style: none;
}

.navItem {
  display: block;
  position: relative;
  cursor: pointer;
}

.navLink {
  text-decoration: none;
  font-weight: 400;
  font-size: 13px;
  line-height: 29px;
  color: #fff;
  position: relative;
  padding: 6px 20px;
  display: block;
  position: relative;
}

.navItem.active .navLink {
  background: #ff364c;
  border-radius: 0px 60px 0px 0px;
  font-weight: 500;
}

.dropdown {
  position: absolute;
  display: block;
  width: 230px;
  background: #1e1e1e;
  left: 20px;
  visibility: hidden;
  opacity: 0;
  z-index: -99;
  transition: 0.2s ease-in;
  border-radius: 8px;
}


.dropdown.active {
  visibility: visible;
  z-index: 9;
  opacity: 1;
  transition: 0.2s ease-in;
}

.dropdown a {
  padding: 10px;
  display: block;
  position: relative;
  text-decoration: none;
  color: #d1d5db;
  transition: 0.4s ease-in-out;
  font-size: 13px;
  font-weight: 400;
  border-radius: 8px;
}

.dropdown a:hover {
  background: #ff364c;
  color: #fff;
  transition: 0.4s ease-in-out;
}

.hideThis {
  display: none;
}

/* // navbar.css */
.navigation-menu ul {
  display: flex;
  padding: 0;
}
.navigation-menu li {
  /* // removes default disc bullet for li tags and applies margin to left & right side */
  list-style-type: none;
  margin: 0 1rem;
}
.navigation-menu li .navlink {
  /* // increases the surface area of the anchor tag to span more than just the anchor text */
  text-decoration: none;
  display: block;
  width: 100%;
}

/* // navbar.css */
#hamburger {
  /* // removes default border on button element */
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
 
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
/* // positions the icon to the right and center aligns it vertically */
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}
#hamburger:hover {
color: #fb4a5e;
}


/* // navbar.css */
@media screen and (max-width: 1080px) {
  #hamburger {
    display: block;
  }
}
/* And hide the ul element in navigation-menu: */

@media screen and (max-width: 1080px) {
  .navigation-menu ul {
    display: none;
  }
}
@media screen and (max-width: 1080px) {
  .navigation-menu.expanded ul {
    display: block;

  }
  
}
@media screen and (max-width: 1080px) {
  .navigation-menu ul {
    /* previous styles */
    display: none;
  }
}

/* // navbar.css */
@media screen and (max-width: 1080px) {
  .navigation-menu ul {
    /* // navigation menu is positioned to start 60px from the top of the document (which is directly below the navbar) */
    position: absolute;
    top: 60px;
    left: 0;
    /* // stacks the li tags vertically  */
    flex-direction: column;
   /* // makes menu span full height and width */
    width: 100%;
    height: auto;
    background-color: #444444;
    border-top: 1px solid black;
  }
  .navigation-menu li {
    
    /* // centers link text and strips off margin */
    text-align: center;
    margin: 0;
  }
  .navigation-menu li .navLink {
    color: white;
    text-align: left;
    /* // increases the surface area of the anchor tag to span the full width of the menu */
    width: 100%;
   
  }
  .navItem.active .navLink {
    background: none;
     color: #ff364c;
  }
  .dropdown {

    background: #1e1e1e;
  }
  .navigation-menu li:hover {
    background-color: #eee !important;
    color: #000 !important;
  }
}