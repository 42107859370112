.newText2 {
  color: #fff;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
}

.redHeading {
  font-weight: 700;
  font-size: 46px;
  line-height: 120%;
  color: #ff364c;
}

.navigationWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
}

.navigationTab {
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  padding: 0px 60px;
  min-height: 70px;
  width: 100%;
  background: #1d1d1d;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  transition: 0.4s ease-in-out;
}

.navigationTab.active,
.navigationTab:hover {
  transition: 0.4s ease-in-out;
  background: #ff364c;
}

.navigationBox {
  margin-top: 80px;
}

.serviceList {
  padding-left: 20px;
}

.serviceList li {
  color: #fff;
  font-weight: 400;
  font-size: 21px;
  line-height: 150%;
}

.serviceList li:not(:first-child) {
  margin-top: 10px;
}

.aboutRight .heading-2 {
  color: #fff;
}

.navigationBoxWrap {
  display: none;
}

.navigationBoxWrap.active {
  display: block;
}
